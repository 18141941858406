import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from 'gatsby'
import SEO from "../components/seo"
import styled from "@emotion/styled"

/* Books Content Container */
const BooksContentContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 10rem;
`

/* Books Header */
const BooksHeaderContainer = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  width: 100%;  
`

const BooksTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`

const BooksLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`

/* Books Archive Looks */
/* Make sure all cover images are the same cropped dimentions! */
const BooksTitleContainer = styled.div`
  cursor: pointer;
  width: 250px;
  margin: 0 auto;
  margin: 1rem;
  text-align: center;
  border: 2px solid black;

  @media (max-width: 425px) {
    width: 80%;
  }
`
const BooksLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  cursor: pointer;
`

const BooksArchiveTitle = styled.h3`
  color: black;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;

  @media (max-width: 320px) {
    line-height: 1.25rem; 
  }
`

const BookArchiveTemplate = ({ data, location }) => {
    const posts = data.allMarkdownRemark.edges;
    return (
      <Layout location={location}>
        <SEO title="Books" />
        <BooksContentContainer>
          <BooksHeaderContainer>
            <BooksTitle>Books</BooksTitle>
            <BooksLine>&nbsp;</BooksLine>
          </BooksHeaderContainer>
          {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              const image = node.frontmatter.image1;

              return (
                <BooksTitleContainer key={node.fields.slug}>
                  <BooksLink to={node.fields.slug}>
                  <img src={image} alt={title} style={{marginBottom: 0}} />
                  <BooksArchiveTitle>
                      {title}
                  </BooksArchiveTitle>
                  </BooksLink>
                </BooksTitleContainer>
              )
          })}
        </BooksContentContainer>
      </Layout>
    )
};

export default BookArchiveTemplate

export const pageQuery = graphql`
query BooksListTemplateQuery {
  allMarkdownRemark(
    sort: { order: [ASC, DESC], fields: [frontmatter___title, frontmatter___publishingDate] }
    filter: {fileAbsolutePath: {regex: "content/books/"}}) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          image1
          publishingDate
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`